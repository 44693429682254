
























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller, {
  parametersInterface,
} from '@/app/ui/controllers/BrandController'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { BRAND_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import EditIconNoSquare from '@/app/ui/assets/edit_icon_nosquare.vue'
import Eye from '@/app/ui/assets/eye.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import { Brand } from '@/domain/entities/Brand'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
    Loading,
    EditIconNoSquare,
    Eye,
    Toggle,
    Modal,
    EmptyState,
  },
})
export default class BrandPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  parameters: parametersInterface = {
    page: 1,
    perPage: BRAND_PAGINATION,
    name: undefined,
  }
  headers = [
    'Brand ID',
    { title: 'Brand Name', width: '45%' },
    '',
    { title: 'Action', width: '15%', class: 'text-center' },
  ]
  modal = ''
  brandId: number | null = null

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || BRAND_PAGINATION,
        name: queries.name || undefined,
      }
    }

    this.fetchBrandList(true)
  }

  get params(): parametersInterface {
    return {
      ...this.parameters,
    }
  }

  get brandDataTable(): Array<
    Array<
      | number
      | Brand
      | { image: string | undefined; name: string | undefined }
      | undefined
    >
  > {
    return controller.brandData.map((brand) => [
      brand.id,
      { id: brand.id, name: brand.name },
      { image: brand.imageUrl, name: brand.name },
      brand,
    ])
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('parameters.name')
  onKeywordChanged(val: string): void {
    if (val === '') {
      this.parameters.page = 1
      this.parameters.name = undefined
    }
  }

  private fetchBrandList(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getBrandList(this.params)
  }

  private showModalUpdateStatus(e: Event, brand: Brand): void {
    e.preventDefault()
    this.modal = brand.active ? 'Deactivate' : 'Activate'
    this.brandId = brand.id || null
  }

  private updateBrandStatus(): void {
    if (this.brandId) {
      this.controller.updateBrandStatus({
        id: this.brandId,
        isActive: this.modal === 'Activate',
      })
      this.brandId = null
    }
  }

  private getBrandNameBy(id: number): string | undefined {
    return this.controller.brandData.find((brand) => brand.id === id)?.name
  }

  @Watch('controller.statusCreateUpdateBrand')
  onStatusCreateUpdateBrandChanged(status: string): void {
    if (status !== '') {
      if (
        status === EventBusConstants.UPDATE_BRAND_STATUS_SUCCESS &&
        controller.dataStatusUpdateStatus !== null
      ) {
        this.$notify({
          title: `Brand ${
            controller.dataStatusUpdateStatus.active ? 'Activated' : 'Deactived'
          }`,
          text: `Brand ${this.getBrandNameBy(
            controller.dataStatusUpdateStatus.id
          )} is successfully ${
            controller.dataStatusUpdateStatus.active ? 'activated' : 'deactived'
          }`,
          type: 'success',
          duration: 5000,
        })
        controller.updateBrandActive({
          id: controller.dataStatusUpdateStatus.id,
          active: controller.dataStatusUpdateStatus.active,
        })
        controller.setStatusCreateUpdateBrand('')
        controller.setDataStatusUpdateStatus(null)

        this.modal = ''
      }
    }
  }

  beforeDestroy(): void {
    controller.setStatusCreateUpdateBrand('')
    controller.setDataStatusUpdateStatus(null)
  }
}
