var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col",attrs:{"data-testid":"bisaBelanja-brand-list"}},[_c('h2',{staticClass:"text-3xl font-semibold mb-5 border-b pb-2"},[_vm._v("Brand")]),_c('div',{staticClass:"flex flex-row items-end mb-8"},[_c('div',{staticClass:"flex flex-grow justify-between"},[_c('div',{staticClass:"w-3/12"},[_c('router-link',{class:{ 'pointer-events-none': _vm.isGuest },attrs:{"data-testid":"bisaBelanja-brand-list__event-create","to":{ name: 'CreateBrand' }}},[_c('Button',{staticClass:"mr-4",attrs:{"disabled":_vm.isGuest}},[_vm._v("Create")])],1)],1),_c('div',{staticClass:"w-3/12"},[_c('TextInput',{attrs:{"data-testid":"bisaBelanja-brand-list__search-input","placeholder":"Search","type":"search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchBrandList(true)}},model:{value:(_vm.parameters.name),callback:function ($$v) {_vm.$set(_vm.parameters, "name", $$v)},expression:"parameters.name"}})],1)])]),(_vm.controller.isLoading)?_c('div',{staticClass:"flex justify-center mb-10"},[_c('loading',{attrs:{"data-testid":"bisaBelanja-brand-list__loader"}})],1):_c('div',{staticClass:"w-full overflow-x-auto"},[_c('DataTable',{attrs:{"data-testid":"bisaBelanja-brand-list__data-table","isLoading":_vm.controller.isLoading,"headers":_vm.headers,"data":_vm.brandDataTable},scopedSlots:_vm._u([{key:"1",fn:function(ref){
var data = ref.data;
return [_c('router-link',{attrs:{"data-testid":("bisaBelanja-brand-list__data-table__event-brand-detail__" + (data.id)),"to":{ name: 'BrandPage', params: { id: data.id } }}},[_vm._v(_vm._s(data.name))])]}},{key:"2",fn:function(ref){
var data = ref.data;
return [_c('img',{staticClass:"max-w-full brand-image",attrs:{"src":data.image,"alt":data.name,"height":"25px"}})]}},{key:"3",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"flex justify-between contents-center"},[_c('router-link',{class:{ 'pointer-events-none': _vm.isGuest },attrs:{"data-testid":("bisaBelanja-brand-list__data-table__event-brand-edit__" + (data.id)),"to":{ name: 'EditBrand', params: { id: data.id } }}},[_c('edit-icon-no-square')],1),_c('router-link',{attrs:{"data-testid":("bisaBelanja-brand-list__data-table__event-brand-detail-icon__" + (data.id)),"to":{ name: 'BrandPage', params: { id: data.id } }}},[_c('eye')],1),_c('toggle',{attrs:{"data-testid":("bisaBelanja-brand-list__data-table__event-modal-update-status__" + (data.id)),"id":data.id,"checked":data.active,"circle-height-class":"h-3","circle-width-class":"w-3","body-height-class":"h-5","body-width-class":"w-8","body-active-class":"bg-bgGreen","body-inactive-class":"bg-bgMatterhorn","body-border-class":"border-0","circle-active-class":"bg-white","circle-inactive-class":"bg-white"},on:{"click":function (e) { return _vm.showModalUpdateStatus(e, data); }}})],1)]}},{key:"data-empty",fn:function(){return [_c('empty-state',{staticStyle:{"margin-bottom":"482px"},attrs:{"data-testid":"bisaBelanja-brand-list__data-table__empty-state","type":"search","text":"Brand You Are Looking For Is Not There","description":"Please change the keyword to search for brand","custom-class":"mt-20","class-text":"mt-6 text-sm","class-desc":"mt-2 text-sm text-secondaryText"}})]},proxy:true}])})],1),_c('PaginationNav',{attrs:{"data-testid":"bisaBelanja-brand-list__pagination","page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        _vm.fetchBrandList()
      })($event)},"input":function () {
        _vm.fetchBrandList()
      }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('Modal',{attrs:{"data-testid":"bisaBelanja-brand-list__modal-confirm-active-inactive","visible":['Activate', 'Deactivate'].includes(_vm.modal),"title":(_vm.modal + " Brand")}},[_c('div',{attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('p',[_vm._v("Are you sure you want to "+_vm._s(_vm.modal.toLowerCase())+" this brand?")])]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"mr-3",attrs:{"data-testid":"bisaBelanja-brand-list__modal-event-cancel","buttonStyle":"outline"},on:{"click":function () {
            _vm.modal = ''
            _vm.brandId = null
          }}},[_vm._v("No")]),_c('Button',{attrs:{"data-testid":"bisaBelanja-brand-list__modal-event-yes"},on:{"click":_vm.updateBrandStatus}},[_vm._v("Yes")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }